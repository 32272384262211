var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-spin",
    {
      staticClass: "box",
      style: _vm.backCss,
      attrs: { spinning: _vm.loading },
      on: { mousedown: _vm.handleMouseDown },
    },
    [
      _c("div", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.is_show_mask,
            expression: "is_show_mask",
          },
        ],
        staticClass: "mask",
        style:
          "width:" +
          _vm.mask_width +
          "left:" +
          _vm.mask_left +
          "height:" +
          _vm.mask_height +
          "top:" +
          _vm.mask_top,
      }),
      _c("FullCalendar", {
        ref: "fullCalendar",
        staticClass: "fullCalendar",
        attrs: { options: _vm.calendarOptions },
        scopedSlots: _vm._u([
          {
            key: "eventContent",
            fn: function (arg) {
              return [
                arg.event.title && arg.event.title != "dropDiv"
                  ? _c(
                      "div",
                      {
                        ref: arg.event.extendedProps.record.id,
                        style: "width:100%;margin-right:5px;margin-bottom:5px",
                      },
                      [
                        arg.event.title
                          ? _c(
                              "div",
                              {
                                style:
                                  "display: flex; justify-content: space-between;width:100%;" +
                                  _vm.getBack(arg.event.extendedProps.record),
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      overflow: "hidden",
                                      width: "92%",
                                      position: "relative",
                                    },
                                  },
                                  [
                                    arg.event.extendedProps.record.taskStatus !=
                                    "已完成"
                                      ? _c("a-checkbox", {
                                          ref:
                                            arg.event.extendedProps.record.id +
                                            "-select",
                                          attrs: {
                                            id:
                                              arg.event.extendedProps.record
                                                .id + "-select",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                            },
                                            change: (e) => {
                                              _vm.dropSelectChange(
                                                e,
                                                arg.event.extendedProps.record
                                              )
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "margin-left": "5px",
                                          overflow: "hidden",
                                          "white-space": "nowrap",
                                          "text-overflow": "ellipsis",
                                          "-o-text-overflow": "ellipsis",
                                          "max-width": "120px",
                                          display: "inline-block",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              arg.event.extendedProps.record
                                                .productSequenceNo
                                                ? arg.event.extendedProps.record
                                                    .productSequenceNo + "-"
                                                : ""
                                            ) +
                                            _vm._s(
                                              arg.event.title.split("-")[0]
                                            ) +
                                            "\n            "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "min-width": "100px",
                                          display: "inline-block",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              "-" +
                                                arg.event.title.split("-")[1] +
                                                "-" +
                                                arg.event.title.split("-")[2] +
                                                "-" +
                                                arg.event.title.split("-")[3]
                                            ) +
                                            "\n            "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                arg.event.extendedProps.record &&
                                "customEvent" !=
                                  arg.event.extendedProps.record.type
                                  ? _c("actions-tasks", {
                                      attrs: {
                                        isChapter: true,
                                        isMyTask: false,
                                        isCalendar: true,
                                        propsParams:
                                          arg.event.extendedProps.record,
                                      },
                                    })
                                  : _vm._e(),
                                arg.event.extendedProps.record.taskStatus ==
                                  "驳回" &&
                                arg.event.extendedProps.record.rejectTask &&
                                arg.event.extendedProps.record.rejectTask
                                  .taskName == "甲方反馈"
                                  ? _c("div", { staticClass: "reject-icon" })
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    )
                  : arg.event.title == "dropDiv"
                  ? _c("div", { staticClass: "drop-list" })
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _c(
        "a-modal",
        {
          attrs: {
            width: "900px",
            visible: _vm.needScheduleTask,
            title: "自动排期",
          },
          on: { ok: _vm.handleEditdateOk, cancel: _vm.handleAddEventCancel },
        },
        [
          _c(
            "div",
            { staticStyle: { display: "flex", "align-items": "center" } },
            [
              _c("span", [_vm._v("自动排期：")]),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    height: "40px",
                    "align-items": "center",
                    "justify-content": "space-between",
                  },
                },
                [
                  _c(
                    "a-radio-group",
                    {
                      model: {
                        value: _vm.taskRadioValue,
                        callback: function ($$v) {
                          _vm.taskRadioValue = $$v
                        },
                        expression: "taskRadioValue",
                      },
                    },
                    [
                      _c("a-radio", { attrs: { value: "1" } }, [_vm._v("是")]),
                      _c("a-radio", { attrs: { value: "2" } }, [_vm._v("否")]),
                    ],
                    1
                  ),
                  _vm.taskRadioValue == "1"
                    ? _c("a-checkbox-group", {
                        staticStyle: {
                          display: "flex",
                          flex: "1",
                          "justify-content": "space-around",
                          "margin-left": "15px",
                        },
                        attrs: { options: _vm.plainOptions },
                        model: {
                          value: _vm.taskCheckedList,
                          callback: function ($$v) {
                            _vm.taskCheckedList = $$v
                          },
                          expression: "taskCheckedList",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
          _vm.taskRadioValue == "2"
            ? _c(
                "div",
                { staticStyle: { "margin-top": "20px" } },
                [
                  _c("chapter-table", {
                    ref: "chapterTable",
                    attrs: { scheduleTasks: _vm.scheduleTasks },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "a-button",
                { key: "back", on: { click: _vm.handleAddEventCancel } },
                [_vm._v("\n        取消\n      ")]
              ),
              _c(
                "a-button",
                {
                  key: "submit",
                  attrs: { type: "primary", loading: _vm.btnLoading },
                  on: { click: _vm.handleEditdateOk },
                },
                [_vm._v("\n        确定\n      ")]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("a-icon", {
        attrs: { type: "check-circle", theme: "filled" },
        on: { click: _vm.save },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }